/* weissenhof-grotesk */
@font-face {
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 400;
  src: local("Weissenhof Grotesk"), local("WeissenhofGrotesk-Regular"),
    url("../assets/fonts/weissenhof-grotesk.woff2") format("woff2");
}

/* weissenhof-grotesk-bold */
@font-face {
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 700;
  src: local("Weissenhof Grotesk Bold"), local("WeissenhofGrotesk-Bold"),
    url("../assets/fonts/weissenhof-grotesk-bold.woff2") format("woff2");
}

/* weissenhof-grotesk-bold-italic */
@font-face {
  font-family: "Weissenhof Grotesk";
  font-style: italic;
  font-weight: 700;
  src: local("Weissenhof Grotesk Bold Italic"),
    local("WeissenhofGrotesk-BoldItalic"),
    url("../assets/fonts/weissenhof-grotesk-bold-italic.woff2") format("woff2");
}

/* weissenhof-grotesk-italic */
@font-face {
  font-family: "Weissenhof Grotesk";
  font-style: italic;
  font-weight: 400;
  src: local("Weissenhof Grotesk Italic"), local("WeissenhofGrotesk-Italic"),
    url("../assets/fonts/weissenhof-grotesk-italic.woff2") format("woff2");
}
