/* Imports the CSS modules */

/* Imports the applications global theme modules */
@import './theme/mui-overrides/buttons.css';
@import './theme/fonts.css';
@import './theme/global.css';
@import './theme/icons.css';
@import './theme/variables.css';

/* Imports Tailwinds modules */
@tailwind base;
@tailwind components;
@tailwind utilities;
