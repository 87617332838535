.sense-logo {
  height: auto;
  width: 280px;
}

.icon-amber {
  color: var(--color-amber);
}

.icon-green {
  color: var(--color-green);
}
