header {
  background-color: var(--bg-color);
  color: #ffffff;
}

li a button.MuiButton-root {
  color: #ffffff;
  font-family: 'Weissenhof Grotesk';
  font-weight: 500;
}

li a:not(.active) button {
  opacity: 0.8;
}

li a.active button {
  border-bottom: 1px solid var(--primary);
  border-radius: 0;
  color: var(--primary);
}
