.MuiButton-contained.MuiButton-containedPrimary {
  background-color: var(--primary);
}

.MuiButton-contained.MuiButton-containedPrimary:hover {
  background-color: var(--primary);
}

.MuiButton-root.MuiButton-textSecondary {
  color: #485cc7;
}

.MuiButton-contained.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12);
  box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
}
