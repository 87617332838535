body {
  background-color: var(--secondary);
  font-family: "Weissenhof Grotesk";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

.adey-background {
  background-color: var(--bg-color);
}

.title {
  color: var(--bg-color);
}